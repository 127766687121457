import React, {
  ReactNode,
  ReactNodeArray,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { useAuth } from './AuthContext';
import { ITenantsRes } from '../Data/Tenant';

interface ITenant {
  tenant: ITenantsRes|null;
  tenantId: null|string;
  setTenantId: (tenantId: string) => void;
}

const TenantContext = React.createContext<ITenant>({
  tenant: null,
  tenantId: null,
  setTenantId: () => {},
});

interface ITenantProviderProps {
    children: ReactNode|ReactNodeArray
}

function TenantProvider(props: ITenantProviderProps) {
  const storedTenantId = localStorage.getItem('iot_tenant_id');
  const [tenantId, setStateTenantId] = useState<null|string>(storedTenantId);
  const { user } = useAuth();
  const [tenant, setStateTenant] = useState<null|ITenantsRes>(null);
  // TODO Revisit this, it's introducing some really wacky behaviour still.
  const setTenantId = (newTenantId:null|string) => {
    setStateTenantId(newTenantId);
    let newTenant = null;
    if (newTenantId) {
      localStorage.setItem('iot_tenant_id', newTenantId);
      newTenant = _.find(user?.tenants, (subject) => parseInt(newTenantId, 0) === subject.id);
      if (newTenantId !== tenantId) {
        window.location.replace(`/tenants/${newTenantId}/deployments`);
      }
    } else {
      localStorage.removeItem('iot_tenant_id');
    }
    //  Set the new tenant as well
    setStateTenant(newTenant ?? null);
  };

  useEffect(
    () => {
      // @ts-ignore
      // eslint-disable-next-line eqeqeq
      const newTenant = _.find(user?.tenants, (subject) => tenantId == subject.id);
      setStateTenant(newTenant ?? null);
    }, [user],
  );
  return (
    <TenantContext.Provider
      value={{
        tenant,
        tenantId,
        setTenantId,
      }}
      {...props}
    />
  );
}

function useTenant() {
  const context = React.useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}

export { TenantProvider, useTenant };
