import React, { ReactNode, ReactNodeArray } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './AuthContext';
import { UserProvider } from './UserContext';
import { TenantProvider } from './TenantContext';


interface IAppProvidersProps {
    children: ReactNode|ReactNodeArray;
}

function AppProviders({ children }: IAppProvidersProps) {
  return (
    <AuthProvider>
      <UserProvider>
        <TenantProvider>
          <SnackbarProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {children}
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </TenantProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default AppProviders;
