import React, { ReactNode, ReactNodeArray } from 'react';
import { useAsync } from 'react-async';
import bootstrapAppData from '../Data/Bootstrap';
import * as authClient from '../Data/AuthClient';
import FullPageSpinner from '../Components/FullPageSpinner';
import { IUser } from '../Data/Users';

interface IAuthContext {
    user: IUser|null,
    signIn: (username: string, password: string) => Promise<void>,
    signOut: () => Promise<void>,
    updateUser: (updates: Partial<IUser>) => Promise<void>,
    buildTenantId: () => string,
    getPersonalTokens: () => Promise<authClient.IToken[]>
    storePersonalToken: (
      request: authClient.ICreateToken,
    ) => Promise<authClient.IPersonalAccessToken>
    revokePersonalToken: (token_id: string) => Promise<void>
}

const AuthContext = React.createContext<IAuthContext>({
  user: null,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  buildTenantId: () => ':tenantId()',
  getPersonalTokens: () => Promise.resolve<authClient.IToken[]>([]),
  storePersonalToken: () => Promise.resolve<authClient.IPersonalAccessToken>({
    token: {
      name: '',
      id: '',
      revoked: false,
      expires_at: '',
    },
    accessToken: '',
  }),
  revokePersonalToken: () => Promise.resolve(),
});

interface IAuthProviderProps {
    children: ReactNode|ReactNodeArray
}

function AuthProvider(props: IAuthProviderProps) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const {
    data = { user: null },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  });

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <FullPageSpinner />;
    }
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
          {error && <pre>{error.message}</pre>}
        </div>
      );
    }
  }

  const buildTenantId = ():string => {
    if (data.user) {
      return `:tenantId(${data.user.tenants.map((option) => option.id).join('|')})`;
    }
    return ':tenantId()';
  };

  const signIn = (username: string, password: string) => authClient.signIn(
    username, password,
  ).then(reload);
  // const register = form => authClient.register(form).then(reload);
  const signOut = () => authClient.signOut().then(reload);
  const updateUser = (updates: Partial<IUser>) => authClient.updateUser(updates).then(reload);
  const getPersonalTokens = () => authClient.getPersonalTokens();
  const storePersonalToken = (
    request: authClient.ICreateToken,
  ) => authClient.storePersonalToken(request);
  const revokePersonalToken = (token_id: string) => authClient.revokePersonalToken(token_id);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        buildTenantId,
        getPersonalTokens,
        storePersonalToken,
        revokePersonalToken,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
