import Axios, { AxiosResponse } from 'axios';
import TokenStorage from './TokenStorage';
import { IUser } from './Users';

export interface IJwt {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface IPasswordReset {
  email: string;
  token: string;
  password: string;
  password_confirm: string;
}

export interface ICreateToken {
  name: string;
}

export interface IPersonalAccessToken {
  token: IToken;
  accessToken: string;
}

export interface IToken {
  id: string,
  name: string,
  revoked: boolean,
  expires_at:string,
}

const getUser = async (): Promise<IUser> => {
  const request = await Axios({
    method: 'get',
    url: '/auth/user',
  });
  return request.data.data;
};

const signIn = async (username: string, password: string): Promise<IUser> => {
  // We use an instance of axios free of interceptors
  const instance = Axios.create();
  const request: AxiosResponse<IJwt> = await instance({
    data: {
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      scope: '',
      username,
      password,
    },
    method: 'post',
    url: '/oauth/token',
  });
  TokenStorage.setAccessToken(request.data.access_token);
  TokenStorage.setRefreshToken(request.data.refresh_token);
  return getUser();
};

const refreshToken = async (): Promise<null> => {
  // We use an instance of axios free of interceptors
  const instance = Axios.create();
  const request: AxiosResponse<IJwt> = await instance({
    data: {
      grant_type: 'refresh_token',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      scope: '',
      refresh_token: TokenStorage.getRefreshToken(),
    },
    method: 'post',
    url: '/oauth/token',
  });
  TokenStorage.setAccessToken(request.data.access_token);
  TokenStorage.setRefreshToken(request.data.refresh_token);
  return Promise.resolve(null);
};

const signOut = () => {
  TokenStorage.clear();
  return Promise.resolve();
};

const signUp = async (token: string, data: any): Promise<void> => {
  const request = await Axios({
    method: 'put',
    url: `/users/${token}`,
    data,
  });
  return request.data;
};

const forgotPassword = async (data: any): Promise<void> => {
  const request = await Axios({
    method: 'post',
    url: '/auth/forgotPassword',
    data,
  });
  return request.data;
};

const resetPassword = async (data: any): Promise<void> => {
  const request = await Axios({
    method: 'post',
    url: '/auth/oneTimeLogin',
    data,
  });
  return request.data;
};

const updateUser = async (data: any): Promise<void> => {
  const request = await Axios({
    method: 'put',
    url: '/auth/user',
    data,
  });
  return request.data;
};

const getPersonalTokens = async (): Promise<IToken[]> => {
  const request = await Axios({
    method: 'get',
    url: '/auth/user/tokens',
  });
  return request.data;
};

const storePersonalToken = async (data: ICreateToken): Promise<IPersonalAccessToken> => {
  const request = await Axios({
    method: 'post',
    url: '/auth/user/tokens',
    data,
  });

  return request.data;
};

const revokePersonalToken = async (token_id: string): Promise<void> => {
  const request = await Axios({
    method: 'delete',
    url: `/auth/user/tokens/${token_id}`,
  });

  return request.data;
};

export {
  signIn,
  refreshToken,
  getUser,
  updateUser,
  signOut,
  signUp,
  forgotPassword,
  resetPassword,
  getPersonalTokens,
  storePersonalToken,
  revokePersonalToken,
};
