
export default class Storage {
  static getAccessToken() {
    return localStorage.getItem('__iot_access_token__');
  }

  static getRefreshToken() {
    return localStorage.getItem('__iot_refresh_token__');
  }

  static setAccessToken(value: string) {
    localStorage.setItem('__iot_access_token__', value);
  }

  static setRefreshToken(value: string) {
    localStorage.setItem('__iot_refresh_token__', value);
  }

  static clear() {
    localStorage.removeItem('__iot_access_token__');
    localStorage.removeItem('__iot_refresh_token__');
  }
}
