import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const FullPageSpinner = () => (
  <Backdrop open timeout={500}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default FullPageSpinner;
