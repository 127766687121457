import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useUser } from './Context/UserContext';
import FullPageSpinner from './Components/FullPageSpinner';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const App = () => {
  const user = useUser();
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp();
  });
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Router>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </Router>
    </React.Suspense>
  );
};


export default App;
